import type { Plugin } from 'vue';
import { computed, ref } from 'vue';
import { getActiveLanguage, I18n, trans, transChoice } from 'laravel-vue-i18n';

const currentLocale = ref();

const setLocale = async (locale: string) => {
    await I18n.getSharedInstance().loadLanguageAsync(locale);
    currentLocale.value = getActiveLanguage();
};

// BCP 47 standard
// TODO: Add support for other contries
const currentLocaleTag = computed(() => {
    return {
        en: 'en-CA',
        fr: 'fr-CA',
    }[currentLocale.value];
});

function getTranslatedValue(value) {
    if (!value) {
        return '';
    }

    return value[currentLocale.value] || value['fr'] || Object.values(value)[0];
}

export {
    currentLocale,
    currentLocaleTag,
    setLocale,
    transChoice as tc,
    trans as t,
    getTranslatedValue,
};

const I18nVue: Plugin = {
    install(app, options) {
        options = { shared: true, ...options };

        const i18n = options.shared ? I18n.getSharedInstance(options, true) : new I18n(options);

        currentLocale.value = getActiveLanguage();

        app.provide('i18n', i18n);
    },
};

export default I18nVue;
