<template>
    <div class="flex items-start">
        <div class="shrink-0">
            <CheckCircleIcon
                v-if="type === 'success'"
                aria-hidden="true"
                class="size-6 text-green-500"
            />
            <ExclamationCircleIcon
                v-if="type === 'error'"
                aria-hidden="true"
                class="size-6 text-red-500"
            />
            <ExclamationTriangleIcon
                v-if="type === 'warning'"
                aria-hidden="true"
                class="size-6 text-yellow-500"
            />
        </div>
        <div class="ml-3 w-0 flex-1 pt-0.5">
            <p
                :class="description ? 'font-normal text-sm text-gray-900' : 'text-xs text-gray-700'"
                v-text="title"
            />
            <p
                v-if="description"
                class="mt-1 text-xs text-gray-500"
                v-text="description"
            />
        </div>
        <div class="ml-4 flex shrink-0">
            <button
                class="inline-flex rounded bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
                type="button"
                @click="emit('close-toast')"
            >
                <span class="sr-only">Close</span>
                <XMarkIcon
                    aria-hidden="true"
                    class="size-5"
                />
            </button>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { CheckCircleIcon, ExclamationCircleIcon, ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
    import { XMarkIcon } from '@heroicons/vue/20/solid';

    import type { NotificationType } from '../types/generated';

    withDefaults(
        defineProps<{
            title: string;
            description?: string;
            type?: NotificationType;
        }>(),
        {
            description: '',
            type: 'success',
        },
    );

    const emit = defineEmits(['close-toast']);
</script>
