import dayjs, { type Dayjs } from 'dayjs';
import { capitalize } from 'lodash-es';
import localeData from 'dayjs/plugin/localeData';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import updateLocale from 'dayjs/plugin/updateLocale';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en-ca';
import 'dayjs/locale/fr-ca';

import { debug } from '@aspect/shared/lib/debug.ts';
import { currentLocaleTag } from '@aspect/shared/plugins/i18n.ts';

type DateType = string | undefined | null | Dayjs | Date;

dayjs.extend(localeData);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

// Start week on Mondays instead of Sundays
dayjs.updateLocale('fr-ca', {
    weekStart: 1,
});
dayjs.updateLocale('en-ca', {
    weekStart: 1,
});

const getShortTimezoneName = (timezone: string) => {
    const dateString = new Intl.DateTimeFormat('en', {
        timeZone: timezone,
        timeZoneName: 'short',
    }).format(new Date());

    return dateString.split(',')[1].trim();
};

let currentTimezone = dayjs.tz.guess();

export const appendTimezone = (): string => {
    const browserTimezone = getShortTimezoneName(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const tenantTimezone = getShortTimezoneName(currentTimezone);

    if (browserTimezone !== tenantTimezone) {
        return ` (${tenantTimezone})`;
    }

    return '';
};

export const date = (dateTime?: DateType, format?: string): Dayjs => {
    if (!dateTime)  {
        const savedOffset = window.sessionStorage.getItem('timeOffset');
        const offset = savedOffset ? JSON.parse(savedOffset) : 0;

        if (offset) {
            dateTime = new Date(Date.now() + offset);
        }
    }

    return dayjs(dateTime, format)
        .locale(currentLocaleTag.value.toLowerCase())
        .tz(currentTimezone);
};

export const format = (dateTime: DateType, format?: string) => {
    if (!dateTime) {
        return '';
    }

    if (!dayjs.isDayjs(dateTime)) {
        dateTime = date(dateTime);
    }

    return dateTime.format(format || 'YYYY-MM-DDTHH:mm:ssZ');
};

export const formatDate = (dateTime: DateType) => {
    return format(dateTime, 'll');
};

export const formatTime = (dateTime: DateType, withSeconds = false) => {
    return format(dateTime, withSeconds ? 'LTS' : 'LT') + appendTimezone();
};

export const formatDateTime = (dateTime: DateType) => {
    return format(dateTime, 'lll') + appendTimezone();
};

export const formatDayOfWeek = (dateTime: DateType) => {
    return capitalize(format(dateTime, 'dddd'));
};

export const formatAge = (dateTime: DateType) => {
    return dayjs()
        .locale(currentLocaleTag.value.toLowerCase())
        .diff(dateTime, 'years')
        .toString();
};

export const setTimezone = (timezone: string) => {
    debug('Timezone', 'Setting to', timezone);
    currentTimezone = timezone;
    dayjs.tz.setDefault(timezone);
};

export const setLocale = (locale: string) => {
    dayjs.locale(locale.toLowerCase());
};

export const getWeekdaysShort = () => {
    return dayjs.weekdaysShort();
};

export const getDaysBetween = (startDate: DateType, endDate: DateType): Dayjs[] => {
    let start = date(startDate);
    const end = date(endDate);
    const days: Dayjs[] = [];

    while (start.isBefore(end) || start.isSame(end)) {
        days.push(start);
        start = start.add(1, 'day');
    }

    return days;
};

export function getDaysOfWeekCount(startDate: DateType, endDate: DateType): Record<number, number> {
    const dayCounts = {
        0: 0, // Sunday
        1: 0, // Monday
        2: 0, // Tuesday
        3: 0, // Wednesday
        4: 0, // Thursday
        5: 0, // Friday
        6: 0  // Saturday
    };

    let currentDate = date(startDate);
    const lastDate = date(endDate);

    while (currentDate.isBefore(lastDate) || currentDate.isSame(lastDate)) {
        dayCounts[currentDate.day()] += 1;
        currentDate = currentDate.add(1, 'day');
    }

    return dayCounts;
}

export const formatSeconds = (seconds: number | null, showHours = true) => {
    if (seconds === null) {
        return '';
    }

    let hours = 0;
    let minutes = 0;

    if (showHours) {
        hours = Math.floor(seconds / (60 * 60));
        seconds = seconds - (hours * 60 * 60);
    }

    minutes = Math.floor(seconds / 60);
    seconds = seconds - (minutes * 60);

    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    return showHours
        ? `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
        : `${minutes}:${formattedSeconds}`;
};
