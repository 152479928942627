import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '@aspect/shared/tailwind.preset.js';

export function remToPx(rem: string | number, includeUnit = true): string | number {
    const remValue = typeof rem === 'string' ? parseFloat(rem) : rem;
    const pxValue = remValue * 16;

    return includeUnit ? `${pxValue}px` : pxValue;
}

const { theme: tailwindTheme } = resolveConfig(tailwindConfig) as any;

export const theme = tailwindTheme;
