import { isEqual } from 'lodash-es';
import Toast from 'vue-toastification';

import type { PluginOptions } from 'vue-toastification';
import type { Plugin } from 'vue';

// https://github.com/Maronato/vue-toastification#api
const defaultOptions: PluginOptions = {
    transition: 'fade-slide',
    icon: false,
    closeButton: false,
    closeOnClick: false,
    filterBeforeCreate(toast: any, toasts: any[]) {
        const hasDuplicate = toasts.some(t => {
            return isEqual(t.content.props, toast.content.props);
        });

        return hasDuplicate ? false : toast;
    },
};

const NotificationsVue: Plugin = {
    install: (app, options = {}) => {
        app.use(Toast, { ...defaultOptions, ...options });
    },
};

export default NotificationsVue;
