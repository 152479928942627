import plugin from 'tailwindcss/plugin';
import { default as flattenColorPalette } from 'tailwindcss/lib/util/flattenColorPalette';

type StepProps = Record<string, string>;

interface OptionsProps {
    experimental?: boolean
    shadowColor?: string
    shadowOffsetX?: string
    shadowOffsetY?: string
    shadowBlur?: string
}

function generateShadows(steps = 1) {
    const classes: string[] = [];
    for (let x = 0; x < steps; x++) {
        classes.push(`calc(var(--ts-text-shadow-x) * ${x}) calc(var(--ts-text-shadow-y) * ${x}) var(--ts-text-shadow-blur) var(--ts-text-shadow-color)`);
    }
    return classes.toString();
}

const Plugin = plugin.withOptions(
    function (options: OptionsProps = {}) {
        return function ({ addBase, addComponents, matchUtilities, matchComponents, theme }: any): void {
            addBase({
                ':root': {
                    '--ts-text-shadow-color': options.shadowColor || 'rgba(0, 0,0,0.45)',
                    '--ts-text-shadow-x': options.shadowOffsetX || '1px',
                    '--ts-text-shadow-y': options.shadowOffsetY || '1px',
                    '--ts-text-shadow-blur': options.shadowBlur || '2px',
                },
            });

            addComponents({
                '.text-shadow': {
                    textShadow: 'var(--ts-text-shadow-x) var(--ts-text-shadow-y) var(--ts-text-shadow-blur) var(--ts-text-shadow-color)',
                },
            });

            matchUtilities(
                {
                    'text-shadow-x': (value: StepProps) => ({
                        '--ts-text-shadow-x': value,
                    }),
                    'text-shadow-y': (value: StepProps) => ({
                        '--ts-text-shadow-y': value,
                    }),
                    'text-shadow-blur': (value: StepProps) => ({
                        '--ts-text-shadow-blur': value,
                    }),
                },
                {
                    values: theme('textShadowSteps'),
                    type: 'length',
                    supportsNegativeValues: true,
                }
            );

            matchUtilities(
                {
                    'text-shadow': (value: StepProps) => ({
                        '--ts-text-shadow-color': value,
                    }),
                },
                {
                    values: flattenColorPalette(theme('colors')),
                    type: 'color',
                }
            );

            addComponents({
                '.text-shadow-sm': {
                    textShadow: generateShadows(theme('textShadowSteps')[0]),
                },
            });

            matchComponents(
                {
                    'text-shadow': (value: number) => ({
                        textShadow: generateShadows(value),
                    }),
                },
                {
                    type: 'number',
                    values: theme('textShadowLong'),
                }
            );
        };
    },
    function () {
        return {
            theme: {
                experimental: false,
                textShadowLong: {
                    sm: 4,
                    md: 8,
                    lg: 12,
                    xl: 16,
                },
                textShadowSteps: {
                    xs: '1px',
                    sm: '2px',
                    md: '3px',
                    lg: '4px',
                    xl: '5px',
                    0: '0',
                    1: '1px',
                    2: '2px',
                    3: '3px',
                    4: '4px',
                    5: '5px',
                    6: '6px',
                    7: '7px',
                    8: '8px',
                    9: '9px',
                    10: '10px',
                },
            },
        };
    }
);

export default Plugin;
